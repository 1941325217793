import './App.css';
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Stars, Center, Text3D } from "@react-three/drei";
import font from './assets/font.json';

function App() {

  return (
    <div className = "sky" style={{width: "100%", height: "100%" }}>
      <Canvas>
      <OrbitControls/>
        < Stars 
          radius={200}
          depth={30}
          count={10000}
          factor={7}
          saturation={0}
          fade={true}
        />
        <Center>
          <Text3D font={font} size={0.35} fontSize={0.5} letterSpacing={0.2}>
            {'Coming soon..\nto a galaxy near you..'}
            <meshNormalMaterial />
          </Text3D>
        </Center>
      </Canvas>
    </div>
  );
}

export default App;
